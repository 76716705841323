<template>
  <div class="h-full p-5">
    <div>
      <h1 style="float:left">对象取值函数列表</h1>
      <el-button type="primary" class="float-right">新增</el-button>
      <div style="clear:both"></div>
    </div>
    <el-table
      v-loading="tableLoading"
      :data="tableDataComp"
      border
      class="mt-5"
      height="calc(100% - 90px)"
      style="width: 100%;"
    >
      <el-table-column label="序号" width="80" prop="index"></el-table-column>
      <el-table-column label="类型" width="80" >
        <template slot-scope="scope">
        {{scope.row.class.indexOf('-') > -1 ? '【全局】' : '【普通】'}}

        </template>
      </el-table-column>
      <el-table-column
        label="函数标识"
        width="200"
        prop="class"
      ></el-table-column>
      <el-table-column
        label="功能描述"
        width="300"
        prop="desc"
      ></el-table-column>
      <el-table-column label="参数描述" prop="inputsDesc"></el-table-column>
      <el-table-column label="操作" prop="options" width="120">
        <template slot-scope="scope">
          <el-button type="primary" @click="showQoute(scope.row)">
            查看引用
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import object from "@/api/objects";

export default {
  name: "valueClasses",
  data() {
    return {
      tableData: [],
      search: "",
      currentPage: 1,
      tableLoading: false,
      total: 0,
      size: 14,
      timer: null,
    };
  },
  computed: {
    tableDataComp() {
      if (!this.tableData.length) {
        return [];
      } else {
        let data = [];
        this.tableData.forEach((item, index) => {
          item.index = index + 1;
          let desc = "";
          let keys = Object.keys(item.inputs);
          keys.forEach((key) => {
            desc += key + "--" + item.inputs[key].desc + " | ";
          });
          desc.slice(0, desc.length - 1);
          item.inputsDesc = desc;
          data.push(item);
        });
        return data;
      }
    },
  },
  methods: {
    getDatas() {
      this.tableLoading = true;
      object.getValueFunctions().then((res) => {
        this.tableLoading = false;
        this.tableData = res.data.data;
      });
    },

    /**
     * @description: 展示引用信息
     */    
    showQoute(data){
      data.type = 'value'
      this.$globalAction('showMetaQoutes',data)
    }
  },

  mounted() {
    this.getDatas();
  },
};
</script>